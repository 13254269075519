<!-- @format -->

<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>page</label>
          <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          <b-button variant="primary" :to="{ name: 'article_create' }"> Add </b-button>
        </b-col>

        <!-- Search -->
        <b-col cols="4" md="4">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search article title..." />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table ref="refInvoiceListTable" responsive :fields="tableColumns" :items="fetchInvoices" bordered show-empty empty-text="暂无数据" small>
      <template #cell(id)="data">
        <b-link class="font-weight-bold"> #{{ data.value }} </b-link>
      </template>

      <template #cell(status)="data">
        <b-badge v-if="data.value == 1" variant="success"> Normal </b-badge>
        <b-badge v-else variant="danger"> 禁用 </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
          </template>
          <b-dropdown-item variant="primary" @click="setItem(data)">
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span>编辑</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteClassification(data.item.id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span>删除</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BBadge, BLink, BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { reactive } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useInvoicesList from './ArticleMain'

import invoiceStoreModule from './AticleModule'

export default {
  name: 'ArticleList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'article-module'
    const items = reactive([])

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }
    const { fetchInvoices, tableColumns, perPage, currentPage, totalInvoices, dataMeta, perPageOptions, searchQuery, refInvoiceListTable, deleteClassification } = useInvoicesList()

    const setItem = data => {
      store.commit('article-module/setDetailArticle', data.item)
      setTimeout(() => {
        router.push({
          path: '/general/menu/article_list/edit',
        })
      }, 500)
    }

    return {
      setItem,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      refInvoiceListTable,
      deleteClassification,

      items,
    }
  },
}
</script>

<style scoped></style>
