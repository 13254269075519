/** @format */
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    detailArticle: {},
  },
  mutations: {
    setDetailArticle(state, payload) {
      state.detailArticle = payload
    },
  },
  actions: {
    fetchArticleList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/article/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteArticle(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/article/delete', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createArticle(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/article/create', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editArticle(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/article/edit', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
